<template>
	<div class="notice_popup" :class="type == '1' ? 'input' : 'prj_itv'">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">+ 기술인재 투입</div>
		
		<template v-if="type == '1'">
			<div class="input_member">
				투입 기술인재 : <tec-summary :value="mberPrjInfo"/>
			</div>
			<div class="prj_name">
				프로젝트명 : <p>'{{mberPrjInfo.projectNm}}'</p>
			</div>
			<div class="rc_field">
				<p>모집분야</p>
				<SelectComp v-model="selectedRecr" :list="recrList" codeKey="projectRecrFieldSeq" nameKey="workCont" title="모집분야를 입력해주세요." @change="joinTerm()" />
			</div>
			<div class="rc_field">
				<p>투입사유</p>
				<InputComp v-model="inputReason" placeholder="투입사유를 입력해주세요"/>
			</div>
			<div class="itv_date">
				<p>참여기간</p><br/>
				<SelectDate tagNm="div" type="date" class="select-box"  v-model="joinStartYmd" from="Y-3" :to="joinStartYmd || 'Y3'">
					<template v-slot:after> 부터</template>
				</SelectDate>
				<SelectDate tagNm="div" type="date" class="select-box" v-model="joinEndYmd" :from="joinStartYmd || 'Y-3'" to="Y3">
					<template v-slot:after> 까지</template>
				</SelectDate>
			</div>

			<div class="itv_date joinAsg">
				<p>참여 소속사</p>
				<AutoComplete type="text" v-model="joinAsgNm" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo"  title="참여 소속사" :rules="{'required':true}">
					<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
				</AutoComplete>
			</div>

			<div class="itv_date rank">
				<p>투입등급</p>
				<SelectComp type="radio" tagName="div" cdId="PRO106" class="select_rank" v-model="putinGrade" title="투입등급" rules="required" :showError="false"/>
			</div>
			
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="next()">다음 &#9654;</div>
			</div>
		</template>

		<template v-else>
			<div class="prj_outline">
				<div class="prj_name">'{{projectInfo.projectNm}}'</div>
				<div class="outline">
					<div class="label ivl02">수 행 사</div>
					<div style="display: inline-flex; width: 560px;">
						<div class="value" style="white-space: nowrap;">{{projectInfo.corpNm}}</div>
						<div class="value location">
							<img src="/images/location.png" alt="위치"/>{{projectInfo.projectLocCont}}
						</div>
					</div>
				</div>
				<div class="outline">
					<div class="label ivl02">담 당 자</div><div class="value">{{projectInfo.mberNm}}</div>
				</div>
				<div class="outline">
					<!-- <div class="label">참 여 기 간</div><div class="value">{{projectInfo.joinTermStartYyyymmdd | date('yyyy.MM.dd')}} - {{projectInfo.joinTermEndYyyymmdd | date('yyyy.MM.dd')}}</div> -->
					<div class="label">참 여 기 간</div><div class="value">{{joinStartYmd | date('yyyy.MM.dd')}} - {{joinEndYmd | date('yyyy.MM.dd')}}</div>
				</div>
				<div class="outline">
					<div class="label">모 집 분 야</div><div class="value">{{projectInfo.workCont}}</div>
				</div>
				<div class="outline">
					<div class="label ivl03" style="margin-right: -3px;">개요</div>
					<div class="value text" style="width: 560px;">{{projectInfo.projectDtlDesc}}</div>
				</div>
				<div class="outline">
					<div class="label">투 입 사 유</div><div class="value">{{inputReason}}</div>
				</div>
				<div class="outline">
					<div class="label">투 입 등 급</div><div class="value"><SelectComp type="text" cdId="PRO106" v-model="putinGrade" /></div>
				</div>
			</div>
			<div class="member" style="margin-left: -10px;"><tec-summary tagName="span" class="name" :value="mberPrjInfo" :corpNm="joinAsgNm"/> 님을 위 프로젝트에 투입하시겠습니까?</div>
			<div class="select-btn">
				<div class="btn" @click="type = '1'">&#9664; 이전</div>
				<div class="btn" @click="putEnd()">완료</div>
			</div>
		</template>
	</div>
</template>

<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	props : {
		param : Object
	},
	data() {
		return {
			data : this.param,
			recrList : [],

			mberPrjInfo : {},

			selectedRecr : '',
			inputReason : '',
			joinStartYmd : '',
			joinEndYmd : '',
			putinGrade : '',
			projectInfo : {},
			selectNum : '',

			corpList : [],
			joinAsgSeq : '',
			joinAsgNm : '',

			type : '1'
		}
	},

	components : {
		tecSummary
	},

	mounted() {
		//console.log('open', 'param', this.param);

		this.getMemberPrjInfo();
		this.corpSearch();	
	},

	methods : {
		getMemberPrjInfo() {
			this.$.httpPost('/api/prj/adm/getMemberPrjInfo', this.param)
				.then(res => {
					this.mberPrjInfo = res.data;
					this.putinGrade = res.data.projectJoinTechGradeCd;
					this.mberPrjInfo.totEvalGradeCd = this.param.totEvalGradeCd;
					this.mberPrjInfo.specialGradeYn = this.param.specialGradeYn;
					
					//this.mberPrjInfo.corpNm			= res.data.projectJoinCorpNm == undefined ? '소속없음' : res.data.projectJoinCorpNm;
					this.getProejctRecrList();
				}).catch(this.$.httpErrorCommon);
		},

		getProejctRecrList() {
			this.$.httpPost('/api/prj/adm/getProejctRecrList', {projectSeq:this.data.projectSeq})
				.then(res => {
					//console.log('getProejctRecrList', res);
					this.recrList = res.data.recrList;

				})
		},

		corpSearch() {
            var param = {
				order : 'asc'
            }
			this.$.httpPost('/api/prj/adm/getScoList', param)
				.then(res => {
					this.corpList = res.data;
				}).catch(this.$.httpErrorCommon);
		},

		setCorpInfo(corpInfo) {

			this.joinAsgSeq = corpInfo.corpSeq;
			this.joinAsgNm = corpInfo.corpNm;

		},

		getTecMberPutProjectInfo() {
			this.$.httpPost('/api/prj/adm/getTecMberPutProjectInfo', {projectSeq:this.data.projectSeq, projectRecrFieldSeq:this.selectedRecr})
				.then(res => {
					//console.log('getTecMberPutProjectInfo', res);
					this.projectInfo = res.data;

					this.type = '2';
				})
		},

		joinTerm() {
			//console.log('!!!');

			if(this.selectedRecr) {
				var selectedRow = {};

				for(var i in this.recrList) {
					if(this.recrList[i].projectRecrFieldSeq == this.selectedRecr) {
						selectedRow = this.recrList[i];
						this.selectNum = i;
					}
				}

				if(Object.keys(selectedRow).length > 0) {
					this.joinStartYmd = selectedRow.joinTermStartYyyymmdd;
					this.joinEndYmd = selectedRow.joinTermEndYyyymmdd;
				}
			}
		},

		next() {
			if(!this.selectedRecr) {
				alert('모집분야를 선택하세요.');
				return false;
			}

			if(this.joinAsgSeq == null || this.joinAsgSeq == '') {
				alert("참여 소속사를 선택해주세요!");
				return false;
			}
			
			// if(this.putinGrade instanceof Error) {
			// 	alert("투입등급을 선택해주세요!");
			// 	return false;
			// }

			for(var i in this.recrList) {
				if(this.recrList[i].recrEndYn && this.recrList[i].recrEndYn == 'Y' && i == this.selectNum) {
					alert('해당 모집분야는 이미 모집 종료됬습니다.');
					return false;
				}
			} 

			if(Object.keys(this.projectInfo).length == 0 || this.projectInfo.projectRecrFieldSeq != this.selectedRecr) this.getTecMberPutProjectInfo();
			else this.type = '2';

		},

		putEnd() {
			
			var param = {};
			param.projectSeq = this.data.projectSeq;
			param.projectRecrFieldSeq = this.projectInfo.projectRecrFieldSeq;
			param.tecMberSeq = this.param.tecMberSeq;
			param.joinTermEndYyyymmdd = this.projectInfo.joinTermEndYyyymmdd;
			param.joinTermStartYyyymmdd = this.projectInfo.joinTermStartYyyymmdd;
			param.inputReason = this.inputReason;
			param.joinStartYmd = this.joinStartYmd;
			param.joinEndYmd = this.joinEndYmd;
			param.putinGrade = this.putinGrade;
			param.interviewStatusCd = '04';
			param.workingAsgSeq = this.mberPrjInfo.corpSeq;
			// param.projectJoinAsgSeq = this.mberPrjInfo.projectJoinCorpSeq;
			param.projectJoinAsgSeq = this.joinAsgSeq;

			//console.log(param);

			this.$.httpPost('/api/prj/adm/setTecMberPutIn', param)
				.then(() => {
					alert('등록이 완료되었습니다.');
					this.$emit('close');
					this.$router.replace('PRJ911M01');
				}).catch(
					err => {
					//console.log('============================='); 
					//console.log(err);
					//console.log(err.response);
					//console.log('============================='); 

					alert(err.response.data.error_description);
					// this.$.httpErrorCommon
				});
		}
	}

}
</script>